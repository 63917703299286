.main-title {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  font-weight: bold;
  font-size: 2.75em;
  text-align: center;
}

.main-menu {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;

  .main-menu-item-nav {
    cursor:pointer;
  }

  .main-menu-item {
    color: rgba(0, 0, 0, 0.4);
    padding-bottom: 0.1em;

    &.active {
      color: #1890ff;
      border-bottom: 0.1em solid #1890ff;
    }
  }
}

.main-content {
  width: 90%;
  flex: 1;
  margin-bottom: 1%;
  margin-top: 1%;

  .stage-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .stage-title {
      font-size: 3em;
      font-weight: bold;
    }

    .stage-subtitle {
      font-size: 1.5em;
    }

    .stage-arrow {
      width: 22.5%;
      margin-bottom: 5%;
      -webkit-transform:rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
}
