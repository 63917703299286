.main-container {
  width: 85%;
  height: 90%;
  background: white;
  display: flex;
  flex-direction: column;

  .top-container {
    width: 100%;
    height: 90%;
    background: #3ba0e9;
    display: flex;
    flex-direction: row;

    .main-container {
      width: 80%;
      height: 100%;
      align-items: center;
    }

    .side-container {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .bottom-container {
    width: 100%;
    height: 10%;
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
