.bottom-left {
  width: 75%;
  height: 100%;

  .input-label {
    width: 10%;
    text-align: right;
    padding-right: 0.5em;
    font-size: 1.15em;
  }

  .result-label {
    @extend .input-label;
    width: 100%;
    font-weight: bold;
    text-align: center;
  }

  .inputs-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .distance-checkbox {
      margin-left: 2.5em;
      margin-right: 0.5em;
      margin-bottom: 0.25em;
    }

    .distance-label {
      @extend .input-label;
      width: auto;
    }
  }
}

.bottom-right {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .next-button {
    width: 45%;
    font-weight: bold;
    height: 57.5%;
  }

  .logo {
    flex: 1;
    height: 80%;
  }
}
