.side-content {
  width: 75%;
  height: 85%;
  color: white;
  overflow: auto;

  .title {
    color: white;
    font-size: 1.25em;
  }

  .item {
    font-size: 1.15em;
  }
}
